/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Seo from '@core/Seo';
import renderRichText from '@common/renderRichText';
import BackLink from '@components/BackLink';

import withModal from '@common/withModal';
import mq from '@mq';
import { richText, hyphenate } from '@mixins';

const DatenschutzPage = ({
  modal,
  data: {
    contentfulDatenschutz: { title, content },
  },
}) => (
  <>
    <Seo pageTitle="Datenschutz" />
    {!modal && <BackLink to="/" />}
    <Header>{title}</Header>
    <Content>{renderRichText(content)}</Content>
  </>
);

const Header = styled.header`
  font: var(--font-h3);
  margin-bottom: var(--space-double);

  ${mq.medium} {
    margin-bottom: 5rem;
  }
`;

const Content = styled.div`
  ${richText};
  ${hyphenate};
`;

export const query = graphql`
  query datenschutzPageQuery {
    contentfulDatenschutz {
      title
      content {
        raw
      }
    }
  }
`;

export default withModal(DatenschutzPage);
